export default {
  cake: {
    56: '0xe5bd6C5b1c2Df8f499847a545838C09E45f4A262', // Milk address here
    97: '0x3eFA66aB2b1690e9BE8e82784EDfF2cF2dc150e0', // Milk address here
  },
  masterChef: {
    56: '0x94098E24FCf4701237CF58ef2A222C1cF5003c86', // COW MasterChef address here
    97: '0xB11C302675FD4a0bD725ecB7e0c3b9F6a3caEa8b', // COW MasterChef address here
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  },
  cow: {
    56: '0x8b6fa031c7d2e60fbfe4e663ec1b8f37df1ba483',
    97: '0x562d2BFc80FD1afF3bF5e4Bd8Fa5312E65305C14',
  },
  milk: {
    1337: '0x1AD8DEc97B0EbD0e76fAA377ed8f6A5D00ff694E',
    56: '0xe5bd6C5b1c2Df8f499847a545838C09E45f4A262',
    97: '0x3eFA66aB2b1690e9BE8e82784EDfF2cF2dc150e0',
  },
  market: {
    56: '0xaBdAEf681D34923184Cba8C0dD8f6ea9B6473f89',
    // 97: '0x590576D878a5CD2c68E9B1f25df3eE6EEc4e80c6',
    97: '0x15d953D5867BF747F53471A849dE77F29Fa91b09',
  },
  happycow: {
    56: '0xf470C4B8564B1069E34Eaf00B26e6892A5391d80',
    97: '0xD220d3E1bab3A30f170E81b3587fa382BB4A6263',
  },
  airnft: {
    56: '0xF5db804101d8600c26598A1Ba465166c33CdAA4b',
    97: '0x74A9Bb4F6b05236507614cA70d32f65436064786',
  },
  staking: {
    56: '0x812825164c271DDb89d494E41488c0409BdEA724',
    97: '0x835e824722A3EE84dF6AA9f5f4F4149609fB567F',
  },
  landnft: {
    56: '',
    // V1: Testnet
    97: '0xa6fC9950810EB96a89fb81F31Fd9071A1d412ce5',
    // BASEURI: https://ipfs.io/ipfs/QmXuQuyznCjvmGzucoisDKRYNFcWYyKX2W3rSSdMhBo8yb/
  },
  cownft: {
    56: '',
    // V1: Testnet
    97: '0x62C54f58D6f50339561e5F043255B7d59AA4c3C8',
  },
  bullnft: {
    56: '',
    // V1: Testnet
    97: '0x0E844540d2212EF9331d7d77F9Ce041e41C5E609',
    // BASEURI: https://ipfs.io/ipfs/QmNVyTfYTATb24hedYyRwX4zoNywTkKCwXbUAqbNxooDhd/
  },
  nftminter: {
    56: '',
    // V1: Testnet
    97: '0x512681F29cE31b159cC5E13A0b11DD577135eafE',
  },
  nftsale: {
    56: '',
    // V1: Testnet
    97: '0xDbfE374D0994dda6bf8D863D548B16e4595E92c3',
  },
  nftfarming: {
    56: '',
    // V1: Testnet
    97: '0xC05C40d90a4D7655f6554C08ba0AEef3C0d3454D',
  },
  nftbreeding: {
    56: '',
    97: '0x35EB60cC8223D62ad1B4e73E2733d356F8481d79',
  },
  proxyAdmin: {
    56: '',
    97: '0x59358715a300C5946A029DB639f78238A45f6dFC',
  },
}
